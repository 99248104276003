import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
  useMemo
} from 'react';
import {
  useAssetMVPermissions,
  useSelectedMVComment,
  useTypedSelector
} from '@hooks';
import { useMixpanel } from 'react-mixpanel-browser';
import AccordionTopPanel from '@pages/MediaViewer/components/AccordionTopPanel/AccordionTopPanel';
import AccordionFilter from '@pages/MediaViewer/components/AccordionFilter';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { List, CollapsePanelProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import CommentEditor from '@pages/MediaViewer/Comments/components/CommentEditor/CommentEditor';
import { formatCommentsTree } from '@pages/MediaViewer/Comments/utils';
import { ReactComponent as CommentSvg } from '@assets/icons/comment.svg';
import { ReactComponent as CommentCloseSvg } from '@assets/icons/comment-close.svg';
import { ReactComponent as EmptySearchSvg } from '@assets/icons/empty-search.svg';
import { ReactComponent as WhiteArrowSvg } from '@assets/icons/arrow-right-without-line.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import classNames from 'classnames';
import CommentTask from '@pages/MediaViewer/Comments/components/CommentTask';
import {
  AssetVersionCommentDto,
  AssetVersionCommentGroupMentionDto,
  AssetVersionCommentMentionDto,
  MemberDto,
  TaskBoardItemDto
} from '@api/Api';
import {
  selectComment,
  setActivePageNumber,
  setCanvasMode
} from '@redux/actions/mediaViewerAction';
import moment from 'moment';
import { toggleSingInExternalReviewerModal } from '@redux/actions/modalAction';
import './Comments.less';
import { useAuth } from '@hooks/useAuth';
import { nonNull } from '@helpers/non-null';
import {
  exportAsCSV,
  exportAsPlainText,
  exportAsXML
} from '@helpers/exportComments';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { parseStringWithLinksIntoHtmlLinks } from '@helpers/parseStringWithLinksIntoHtmlLinks';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { useOrganization } from '@components/OrganizationBoundary';
import {
  openTaskModal,
  setAssetListNeedRefresh,
  setListsNeedRefreshInCampaign
} from '@redux/reducers/tasks';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { useCreateTask } from '@hooks/tasks';
import { useFetch } from '@hooks/useFetch';
import {
  assetStateSelector,
  boardsSelector,
  campaignStateSelector
} from '@redux/selectors/tasks';
import {
  fetchAssetAssignees,
  fetchCampaignTaskBoards
} from '@redux/actions/tasks';
import { MediaViewerActionTypes } from '@redux/types/mediaViewerType';
import CommentTree from '@pages/MediaViewer/Comments/CommentTree';
import { FabricContext } from '@context/FabricContext';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import { useQuery } from '@tanstack/react-query';
import { useDebounced } from '@helpers/useDebounced';

type ICommentsProps = Omit<CollapsePanelProps, 'header'> & {
  canCreateTask: boolean;
  commentMentions: boolean;
};

const sortMenu: ItemType[] = [
  { label: 'Priority', key: 'Priority' },
  { label: 'Date created', key: 'Date created' },
  { label: 'Date modified', key: 'Date modified' }
];

const commentsMenu: ItemType[] = [
  { label: 'All comments', key: 'All comments' },
  { label: 'My comments', key: 'My comments' }
];

function Comments({
  canCreateTask,
  commentMentions,
  ...props
}: ICommentsProps) {
  const {
    addNewComment,
    removeComment,
    setCommentLike,
    editComment,
    getPotentialMentions,
    getPotentialGroupMentions,
    workspace
  } = useOutletContext<any>();
  const { getObjects, clearCanvas, canvas } = useContext(FabricContext);
  const mixpanel = useMixpanel();
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useTypedDispatch();
  const { currentOrganization } = useOrganization(false);
  const createTask = useCreateTask();
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const commentsTopRef = useRef<HTMLDivElement>(null);
  const canLeaveComment = useAssetMVPermissions('comment');
  const canReadComment = useAssetMVPermissions('readComments');

  const commentsList = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.commentsList
  );
  const asset = useTypedSelector(({ mediaViewer }) =>
    nonNull(mediaViewer.assets)
  );
  const assetId = asset.id;
  const selectedVersion = useTypedSelector(({ mediaViewer }) =>
    nonNull(mediaViewer.selectedVersion)
  );
  const selectedCommentId = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedCommentId
  );
  const activePageNumber = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.activePageNumber
  );
  const canvasMode = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.canvasMode
  );
  const playerTime = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.playerTime
  );
  const [selectedUser, setSelectedUser] = useState<MemberDto | null>(null);
  const [selectedBoard, setSelectedBoard] = useState<TaskBoardItemDto | null>(
    null
  );
  const campaignId = useTypedSelector(({ mediaViewer }) =>
    nonNull(mediaViewer.assets?.campaignId)
  );
  const campaignName = useTypedSelector(({ mediaViewer }) =>
    nonNull(mediaViewer.assets?.campaignName)
  );
  const workspaceId = useTypedSelector(({ mediaViewer }) =>
    nonNull(mediaViewer.assets?.workspaceId)
  );
  const assets = useTypedSelector(({ mediaViewer }) => mediaViewer.assets);
  const zoomFactor = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.zoomFactor
  );
  const assetType = selectedVersion?.metadata.type;
  const isAudio = assetType === 'video' || assetType === 'audio';
  const isDocument =
    assetType === 'document' ||
    assetType === 'presentation' ||
    assetType === 'spreadsheet';
  const isImage =
    assetType === 'camera_raw_image' ||
    assetType === 'raster_image' ||
    assetType === 'vector_image';
  const checkboxValueType =
    (isDocument && 'pageNumber') || (isAudio && 'timeCode') || null;

  useFetch({
    key: `campaign-task-board-${campaignId}`,
    disabled: user?.type !== 'internal',
    selector: (state) =>
      campaignStateSelector(state, { campaignId }).boards.fetch,
    fetch: (fetchType) => {
      return dispatch(
        fetchCampaignTaskBoards({
          campaignId,
          fetchType
        })
      );
    }
  });
  useEffect(() => {
    dispatch(setListsNeedRefreshInCampaign({ campaignId }));
  }, [campaignId, dispatch]);

  useFetch({
    key: `asset-assignees-${assetId}`,
    disabled: user?.type !== 'internal',
    selector: (state) =>
      assetStateSelector(state, { assetId }).potentialAssignees.fetch,
    fetch: (fetchType) => {
      return dispatch(
        fetchAssetAssignees({
          workspaceId,
          assetId,
          fetchType
        })
      );
    }
  });
  useEffect(() => {
    dispatch(setAssetListNeedRefresh({ assetId }));
  }, [assetId, dispatch]);

  const taskBoards = useTypedSelector((state) =>
    boardsSelector(state, { campaignId })
  ).filter((x) => x.id !== 'archived' && x.id !== 'no_assigned');
  const potentialAssignees = useTypedSelector(
    (state) => assetStateSelector(state, { assetId }).potentialAssignees.list
  );

  const [isShowTask, setIsShowTask] = useState<boolean>(false);
  const [editCommentId, setEditCommentId] = useState<string | null>(null);
  const [replyToCommentId, setReplyToCommentId] = useState<string | null>(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(true);
  const [sortSelected, setSortSelected] = useState<string>('');
  const [showReplyOfCommentId, setShowReplyOfCommentId] = useState<
    string | null
  >(null);
  const [sortCommentsSelected, setSortCommentsSelected] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const queryCommentId = searchParams.get('commentId');
  const selectedComment = useSelectedMVComment();
  useOnClickOutside(commentsContainerRef, (e) => {
    if (
      selectedComment?.timeCode?.toSeconds &&
      (e.target.closest('.video_control_bar_container') ||
        e.target.closest('.video_control_bar_track'))
    )
      return;
    if (
      canvasMode === 'view' &&
      selectedCommentId !== 'new' &&
      selectedCommentId !== 'reply' &&
      !e.target.classList.contains('upper-canvas') &&
      !e.target.classList.contains('toggle_open') &&
      !e.target.closest('.media_viewer_panel-action-btn') &&
      !e.target.closest('.media_viewer_panel-action-slide')
    ) {
      dispatch(setCanvasMode('view'));
      dispatch(selectComment(null));
    }
  });

  const dropdownMenuItems: ItemType[] = [
    {
      label: (
        <UpgradePlanTooltip
          text=" to download comments."
          isCanVisible={!currentOrganization?.entity?.features.downloadComments}
        >
          <span>Export comments as</span>
        </UpgradePlanTooltip>
      ),
      expandIcon: currentOrganization?.entity?.features.downloadComments ? (
        <WhiteArrowSvg />
      ) : (
        <UpgradePlanSvg style={{ marginLeft: 4 }} />
      ),
      disabled: !currentOrganization?.entity?.features.downloadComments,
      key: 'submenu',
      popupClassName: 'media_viewer_submenu media_viewer_mobile-overlay',
      popupOffset: [12, 0],
      onTitleClick: ({ domEvent }) => domEvent.stopPropagation(),
      children: [
        { label: 'CSV', key: 'CSV' },
        { label: 'XML', key: 'XML' },
        { label: 'Plain text', key: 'Plain text' }
      ]
    }
  ];

  const onExport = (value: 'CSV' | 'XML' | 'Plain text') => {
    if (!workspace || !assets || !selectedVersion || !formattedComments) return;
    if (value === 'Plain text')
      exportAsPlainText(selectedVersion, formattedComments);
    if (value === 'CSV')
      exportAsCSV(workspace.name, assets, selectedVersion, commentsList);
    if (value === 'XML')
      exportAsXML(workspace.name, assets, selectedVersion, formattedComments);
  };

  const formattedComments = useCallback(() => {
    let list = formatCommentsTree(commentsList);
    if (list.length <= 0) return [];

    if (searchQuery) {
      list = list.filter(({ text }: { text: string }) =>
        text.includes(searchQuery)
      );
    }

    if (sortCommentsSelected === 'My comments')
      list = list.filter((el: any) => el.user?.me);

    if (sortSelected === 'Priority')
      list = list.sort((x: any, y: any) =>
        x.priority === y.priority ? 0 : x ? -1 : 1
      );

    if (sortSelected === 'Date created')
      list = list.sort(
        (x: any, y: any) =>
          +moment(y.createdAt).format('X') - +moment(x.createdAt).format('X')
      );

    if (sortSelected === 'Date modified')
      list = list.sort(
        (x: any, y: any) =>
          (+moment(y.updatedAt).format('X') || 0) -
          (+moment(x.updatedAt).format('X') || 0)
      );
    return list;
  }, [commentsList, sortCommentsSelected, sortSelected, searchQuery])();

  useEffect(() => {
    if (!commentsList.length) return;
    if (!queryCommentId) return;
    const repliedCommentId = commentsList.find(
      (el) => el.id === queryCommentId
    )?.repliedToId;
    const commentPage = commentsList.find(
      (it) => it.id === queryCommentId
    )?.documentPage;
    if (commentPage) {
      dispatch(setActivePageNumber(commentPage));
    }
    if (repliedCommentId) {
      setShowReplyOfCommentId(repliedCommentId);
      if (showReplyOfCommentId)
        document
          .querySelector(`[data-comment-id="${queryCommentId}"]`)
          ?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
    } else {
      document
        .querySelector(`[data-comment-id="${queryCommentId}"]`)
        ?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
    }
    searchParams.delete('commentId');
    setSearchParams(searchParams);
    dispatch(selectComment(queryCommentId));
  }, [commentsList, queryCommentId]);

  useEffect(() => {
    if (selectedCommentId !== 'new' && canvasMode !== 'edit') {
      setEditCommentId(null);
    }
  }, [selectedCommentId, canvasMode]);

  useEffect(() => {
    if (selectedCommentId === 'new') setEditCommentId('new');
    const topParentId = commentsList.find(
      (it) => selectedCommentId === it.id
    )?.topParentId;
    if (topParentId) setShowReplyOfCommentId(topParentId);
    if (selectedCommentId) {
      document
        .querySelector(`[data-comment-id="${selectedCommentId}"]`)
        ?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
    }
  }, [selectedCommentId, commentsList, showReplyOfCommentId]);

  const onTurnCommentIntoTask = async (
    comment: AssetVersionCommentDto,
    selectedBoard?: TaskBoardItemDto | null,
    selectedUser?: MemberDto | null
  ) => {
    try {
      let parsedText = comment.text;
      let parsedName = comment.text
        .replace(/<br[^>]*>/gi, ' ')
        .replace(/<[^>]*>?/gm, '');
      comment.mentions.forEach((el) => {
        parsedName = parsedName.replaceAll(`{{${el.id}}}`, '');
        parsedText = parsedText.replaceAll(
          `{{${el.id}}}`,
          `<span data-type="mention" class="b-suggestion-user" data-label="${el.firstName} ${el.lastName}" contenteditable="false">@${el.firstName} ${el.lastName}</span>`
        );
      });
      comment.groupMentions.forEach((el) => {
        parsedName = parsedName.replaceAll(`{{${el.id}}}`, '');
        parsedText = parsedText.replaceAll(
          `{{${el.id}}}`,
          `<span data-type="mention" class="b-suggestion-user" data-label="${el.name}" contenteditable="false">@${el.name}</span>`
        );
      });
      parsedName = parsedName.replaceAll(
        /{{(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})}}/g,
        ``
      );
      if (parsedName.length > 60) parsedName = `${parsedName.slice(0, 57)}...`;
      parsedText = parsedText.replaceAll(
        /{{(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})}}/g,
        `<span data-type="mention" class="b-suggestion-user" data-label="Deleted User" contenteditable="false">@Deleted User</span>`
      );

      const boardId = selectedBoard?.id;
      const { task } = await createTask({
        workspaceId,
        campaign: {
          id: campaignId,
          name: campaignName
        },
        name: parsedName.trim() || 'New task',
        description: parseStringWithLinksIntoHtmlLinks(parsedText),
        assetVersionCommentId: comment.id,
        assignees: selectedUser
          ? [{ ...selectedUser, hasAccessToTask: true }]
          : undefined,
        board: {
          id: boardId || 'no_assigned'
        },
        taskPosition: 'start-of-list'
      }).unwrap();
      if (user) {
        MixpanelService.track(
          mixpanel,
          user.id,
          MixpanelEventType.MANAGE_ASSETS,
          {
            addToFavorite: false,
            deleteAsset: false,
            newAsset: false,
            externalLinkShare: false,
            approvalRouting: false,
            newCommentAsset: false,
            annotationLeft: false,
            taskFromComment: true
          }
        );
      }
      dispatch({
        type: MediaViewerActionTypes.UPDATE_ASSET_INTERNAL_COMMENT,
        payload: {
          ...comment,
          task: { id: task.id }
        }
      });
      dispatch(
        openTaskModal({
          taskId: task.id,
          descriptionPlaceholder:
            'Describe your task so your teammates know what to do.'
        })
      );
    } catch (error) {
      console.warn(error, '-> create task');
    }
  };

  const onAddComment = async (
    text: string,
    rawText: string,
    mentions: string[],
    groupMentions: string[],
    parentId?: string
  ) => {
    const timeCode = {
      fromSeconds: playerTime[0] || 0,
      toSeconds: playerTime[1]
    };
    const documentPage = activePageNumber;
    let data: any = {
      text: parseStringWithLinksIntoHtmlLinks(text),
      annotations: getAnnotations(),
      mentions,
      groupMentions,
      rawText
    };
    if (isCheckboxChecked && isAudio) {
      data = {
        ...data,
        timeCode
      };
    }
    if (isCheckboxChecked && isDocument) {
      data = {
        ...data,
        documentPage
      };
    }
    if (parentId && parentId !== 'new') {
      const parentComment = commentsList.find((it) => it.id === parentId);
      let replyToRawText = parentComment?.text;
      parentComment?.mentions.forEach((el: any) => {
        replyToRawText = parentComment.text.replace(
          `{{${el.id}}}`,
          `@${el.firstName} ${el.lastName ?? ''}`.trim()
        );
      });
      parentComment?.groupMentions.forEach((el: any) => {
        replyToRawText = parentComment.text.replace(
          `{{${el.id}}}`,
          `@${el.name}`
        );
      });
      data = {
        ...data,
        replyToId: parentId,
        replyToRawText
      };
    } else
      data = {
        ...data,
        assetVersionId: selectedVersion?.id || ''
      };

    const comment = await addNewComment(data, parentId);
    clearCanvas();
    document
      .querySelector(`[data-comment-id="${comment.id}"]`)
      ?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    dispatch(setCanvasMode('view'));
    dispatch(selectComment(null));

    if (isShowTask) {
      onTurnCommentIntoTask(comment, selectedBoard, selectedUser);
    }
  };

  const onDeleteComment = useCallback(
    async (id) => {
      await removeComment(id);
    },
    [removeComment]
  );

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (
        e.key === 'Delete' &&
        selectedComment?.id &&
        canvasMode === 'view' &&
        selectedComment.user?.me
      ) {
        dispatch(selectComment(null));

        onDeleteComment(selectedComment.id);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [
    dispatch,
    canvasMode,
    onDeleteComment,
    selectedComment?.id,
    selectedComment?.user?.me
  ]);

  const onToggleLikeComment = async ({
    id,
    value
  }: {
    id: string;
    value: boolean;
  }) => {
    const { type, pictureUrl, email, firstName, lastName } = nonNull(user);
    const commentUser = {
      type,
      picture: pictureUrl ?? '',
      email,
      firstName,
      lastName: lastName || '',
      me: true
    };
    await setCommentLike({ id, value }, commentUser);
  };

  const onEditComment = async (
    text: string,
    rawText: string,
    mentions: string[],
    groupMentions: string[],
    id: string
  ) => {
    const comment = commentsList.find((it) => it.id === id);
    if (!comment) return;
    const data = {
      id,
      text: parseStringWithLinksIntoHtmlLinks(text),
      annotations: getAnnotations(),
      rawText,
      mentions: {
        add: mentions.filter(
          (id) => !comment.mentions.some((x) => x.id === id)
        ),
        remove: comment.mentions.filter((x) => !mentions.includes(x.id))
      },
      groupMentions: {
        add: groupMentions.filter(
          (id) => !comment.groupMentions.some((x) => x.id === id)
        ),
        remove: comment.groupMentions.filter(
          (x) => !groupMentions.includes(x.id)
        )
      },
      timeCode:
        isCheckboxChecked && isAudio
          ? {
              fromSeconds: playerTime[0] || 0,
              toSeconds: playerTime[1]
            }
          : undefined
    };
    await editComment(data);
    clearCanvas();
    dispatch(setCanvasMode('view'));
  };

  const getAnnotations = (): string | null => {
    if (!canvas) return null;
    const json = canvas.toJSON(['assetAngle', 'userEmail', 'userId']) as any;
    if (!json.objects.length) return null;

    return JSON.stringify(json.objects);
  };

  const [potentialMentionsSearchQuery, setPotentialMentionsSearchQuery] =
    useState<string>('');
  const debouncedPotentialMentionsSearchQuery = useDebounced(
    potentialMentionsSearchQuery,
    300
  );
  const potentialMentionsQuery = useQuery({
    queryKey: [
      'asset',
      'comment',
      'potential-mentions',
      selectedVersion.id,
      debouncedPotentialMentionsSearchQuery
    ],
    keepPreviousData: true,
    queryFn: async () => {
      const list: AssetVersionCommentMentionDto[] = await getPotentialMentions({
        assetVersionId: selectedVersion.id,
        searchQuery: potentialMentionsSearchQuery
      });
      return list;
    }
  });
  const potentialMentions = useMemo(
    () => potentialMentionsQuery.data ?? [],
    [potentialMentionsQuery.data]
  );
  const potentialGroupMentionsQuery = useQuery({
    queryKey: [
      'asset',
      'comment',
      'potential-group-mentions',
      selectedVersion.id,
      debouncedPotentialMentionsSearchQuery
    ],
    keepPreviousData: true,
    queryFn: async () => {
      const list: AssetVersionCommentGroupMentionDto[] =
        await getPotentialGroupMentions({
          assetVersionId: selectedVersion.id,
          searchQuery: potentialMentionsSearchQuery
        });
      return list;
    }
  });
  const potentialGroupMentions = useMemo(
    () => potentialGroupMentionsQuery.data ?? [],
    [potentialGroupMentionsQuery.data]
  );

  const canExternalMakeAction = (callBack: any) => {
    if (!user) {
      dispatch(
        toggleSingInExternalReviewerModal({
          visible: true
        })
      );
      return;
    }
    callBack();
  };

  return (
    <AccordionTopPanel
      {...props}
      name="Comments"
      dropdownMenu={commentsList?.length ? dropdownMenuItems : null}
      onDropdownHandler={onExport}
      count={commentsList?.length}
    >
      <>
        {canReadComment && (
          <AccordionFilter
            sortMenu={sortMenu}
            onSortHandler={setSortSelected}
            onDropdownHandler={setSortCommentsSelected}
            dropdownMenu={commentsMenu}
            dropdownMenuText={sortCommentsSelected || 'All comments'}
            onSearchHandler={(v) => setSearchQuery(v)}
            exportMenu={dropdownMenuItems}
            onExportHandler={onExport}
          />
        )}

        <div
          className="media_viewer_comments_container"
          ref={commentsContainerRef}
        >
          <div ref={commentsTopRef} />
          <div className="media_viewer_comments_top">
            {canLeaveComment && (
              <CommentEditor
                commentMentions={commentMentions}
                editCommentId={editCommentId}
                setEditCommentId={setEditCommentId}
                editorFor="new"
                autoFocus={false}
                isFocused={editCommentId === 'new'}
                replyToCommentId={replyToCommentId}
                setReplyToCommentId={setReplyToCommentId}
                onSubmitEditor={onAddComment}
                isCheckboxChecked={isCheckboxChecked}
                setIsCheckboxChecked={setIsCheckboxChecked}
                checkboxValueType={checkboxValueType}
                potentialMentions={potentialMentions}
                potentialGroupMentions={potentialGroupMentions}
                searchPotentialMentions={(v) =>
                  setPotentialMentionsSearchQuery(v)
                }
              />
            )}

            {user?.type === 'internal' && editCommentId === 'new' && (
              <CommentTask
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                selectedBoard={selectedBoard}
                setSelectedBoard={setSelectedBoard}
                isShowTask={isShowTask}
                setIsShowTask={setIsShowTask}
                potentialAssignees={potentialAssignees}
                boardList={
                  selectedVersion?.versionNumber ===
                  assets?.asset?.versionsCount
                    ? taskBoards
                    : []
                }
                canCreateTask={canCreateTask}
              />
            )}
          </div>
          <List
            className={classNames(
              'media_viewer_comments_list',
              !formattedComments.length && 'media_viewer_comments_list-empty'
            )}
            dataSource={formattedComments}
            rowKey="id"
            itemLayout="horizontal"
            renderItem={(comment) => (
              <CommentTree
                commentMentions={commentMentions}
                comment={comment}
                editCommentId={editCommentId}
                selectedCommentId={selectedCommentId}
                replyToCommentId={replyToCommentId}
                showReplyOfCommentId={showReplyOfCommentId}
                isCheckboxChecked={isCheckboxChecked}
                checkboxValueType={checkboxValueType}
                onReply={(comment) => {
                  canExternalMakeAction(() => {
                    setEditCommentId(null);
                    if (replyToCommentId === comment.id) {
                      dispatch(setCanvasMode('view'));
                      setReplyToCommentId(null);
                      dispatch(selectComment(null));
                    } else {
                      setReplyToCommentId(comment.id);
                      setShowReplyOfCommentId(
                        comment.topParentId || comment.id
                      );
                      dispatch(setCanvasMode('edit'));
                      dispatch(selectComment('reply'));
                    }
                  });
                }}
                onLikeClick={(comment) => {
                  canExternalMakeAction(() => {
                    const hasLike = comment.likes.find((el: any) => el.me);
                    onToggleLikeComment({ value: !hasLike, id: comment.id });
                  });
                }}
                onShowReplyClick={(comment) => {
                  if (showReplyOfCommentId === comment.id)
                    setShowReplyOfCommentId(null);
                  else setShowReplyOfCommentId(comment.id);
                  setReplyToCommentId(null);
                }}
                potentialMentions={potentialMentions}
                potentialGroupMentions={potentialGroupMentions}
                searchPotentialMentions={(v) =>
                  setPotentialMentionsSearchQuery(v)
                }
                onAddComment={onAddComment}
                setEditCommentId={setEditCommentId}
                setReplyToCommentId={setReplyToCommentId}
                setIsCheckboxChecked={setIsCheckboxChecked}
                onEditComment={onEditComment}
                onDeleteComment={onDeleteComment}
                onTurnCommentIntoTask={onTurnCommentIntoTask}
                canCreateTask={canCreateTask}
              />
            )}
            locale={{
              emptyText: !canReadComment ? (
                <div className="media_viewer_comments_empty">
                  <CommentCloseSvg className="b-comment-icon" />
                  You have view-only permission
                </div>
              ) : searchQuery && !formattedComments.length ? (
                <div className="media_viewer_comments_empty">
                  <EmptySearchSvg className="b-comment-icon" />
                  <span className="title">No results found</span>
                  It seems we can’t find any results based on your search
                </div>
              ) : (
                <div className="media_viewer_comments_empty">
                  <CommentSvg className="b-comment-icon" />
                  No comments yet
                </div>
              )
            }}
          />
        </div>
      </>
    </AccordionTopPanel>
  );
}

export default Comments;
