import { FabricContext } from '@context/FabricContext';
import { useTypedSelector } from '@hooks';
import { useContext, useEffect, useCallback, useState } from 'react';

export default function useHasAnnotations() {
  const selectedCommentId = useTypedSelector(
    (state) => state.mediaViewer.selectedCommentId
  );
  const { canvas, getObjects } = useContext(FabricContext);
  const [has, setHas] = useState<boolean>(false);

  const hasObjects = useCallback(() => {
    const objects = getObjects();
    setHas(objects.length > 0);
  }, [getObjects]);

  useEffect(() => {
    setHas(false);
  }, [selectedCommentId]);

  useEffect(() => {
    if (!canvas) return;
    canvas.on('object:history:add', () => {
      hasObjects();
    });
    canvas.on('object:history:remove', () => {
      hasObjects();
    });
    return () => {
      canvas.off('object:history:add');
      canvas.off('object:history:remove');
    };
  }, [canvas, hasObjects]);
  return has;
}
