import React, { useMemo, useState, useCallback } from 'react';
import { Row, Col, Tooltip, Dropdown, Menu } from 'antd';
import { ReactComponent as HighPrioritySvg } from '@assets/icons/high-priority.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';

import { hashedColor } from '@helpers/hashedColor';
import { formatDuration } from '@helpers/formatDuration';
import {
  selectComment,
  setActivePageNumber,
  setCanvasMode,
  setIsTimecodeChecked
} from '@redux/actions/mediaViewerAction';
import { useDispatch } from 'react-redux';
import { useAssetMVPermissions, useTypedSelector } from '@hooks';
import commentShortTime from '@helpers/commentShortTime';
import commentLongTime from '@helpers/commentLongTime';
import UserAvatar from '@components/UserAvatar';
import { useResponsive } from '@hooks/useResponsive';
import { AssetVersionCommentDto, AudioVideoMetadataDto } from '@api/Api';

const menuItems = [
  {
    label: 'Edit',
    key: 'edit'
  },
  {
    label: 'Copy link',
    key: 'copyLink'
  },
  {
    label: 'Turn into task',
    key: 'turnIntoTask'
  },
  {
    label: 'Delete',
    key: 'delete'
  }
];

function CommentItem({
  comment,
  setEditCommentId,
  setReplyToCommentId,
  onDeleteComment,
  onTurnCommentIntoTask,
  canCreateTask
}: {
  comment: AssetVersionCommentDto;
  setEditCommentId: (a: string) => void;
  setReplyToCommentId: (a: string | null) => void;
  onDeleteComment: (a: string) => void;
  onTurnCommentIntoTask: (comment: AssetVersionCommentDto) => void;
  canCreateTask: boolean;
}) {
  const dispatch = useDispatch();
  const { isMdWidth } = useResponsive();
  const canLeaveComment = useAssetMVPermissions('comment');
  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );
  const selectedCommentId = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedCommentId
  );
  const timeFormat = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.timeFormat
  );
  const isSourceSelected = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isSourceSelected
  );
  const [showMoreActions, setShowMoreActions] = useState<boolean>(false);
  const pageNumber = useMemo(() => {
    return comment.documentPage;
  }, [comment.documentPage]);
  const _menuItems = useMemo(() => {
    const newMenuItems = menuItems.filter((el: any) => {
      const _closeEdit =
        (!comment.permissions.update || !canLeaveComment) && el.key === 'edit';
      const _closeDelete =
        (!comment.permissions.delete || !canLeaveComment) &&
        el.key === 'delete';
      const _closeTurnIntoTask =
        el.key === 'turnIntoTask' && (!canCreateTask || comment.task);
      if (_closeEdit || _closeDelete || _closeTurnIntoTask) return false;
      return true;
    });
    return isMdWidth
      ? [...newMenuItems, { label: 'Cancel', key: 'cancel' }]
      : newMenuItems;
  }, [canCreateTask, canLeaveComment, comment, isMdWidth, selectedVersion]);

  const handlerAction = async (key: string) => {
    const hasSearch = !!window.location.search;
    switch (key) {
      case 'edit':
        if (comment.timeCode?.fromSeconds) dispatch(setIsTimecodeChecked(true));
        setEditCommentId(comment.id);
        setReplyToCommentId(null);
        dispatch(selectComment(comment.id));
        dispatch(setCanvasMode('edit'));
        setShowMoreActions(false);
        break;
      case 'copyLink':
        setShowMoreActions(false);
        navigator.clipboard.writeText(
          `${window.location.href}${hasSearch ? '&' : '?'}commentId=${
            comment.id
          }`
        );
        break;
      case 'turnIntoTask':
        setShowMoreActions(false);
        onTurnCommentIntoTask(comment);
        break;
      case 'delete':
        setShowMoreActions(false);
        onDeleteComment(comment.id);
        if (comment.id === selectedCommentId) dispatch(selectComment(null));
        break;
      default:
        setShowMoreActions(false);
    }
  };
  const prepareHtml = () => {
    let html: string = comment.text;
    comment.mentions.forEach((el) => {
      html = html.replaceAll(
        `{{${el.id}}}`,
        `<span class="b-suggestion-user">@${el.firstName} ${el.lastName}</span>`
      );
    });
    comment.groupMentions.forEach((el) => {
      html = html.replaceAll(
        `{{${el.id}}}`,
        `<span class="b-suggestion-user">@${el.name}</span>`
      );
    });
    html = html.replaceAll(
      /{{(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})}}/g,
      `<span class="b-suggestion-user"> 
          @Deleted User
        </span>`
    );
    return html;
  };
  const renderCommentTime = useCallback(
    (pos: string) => {
      const metadata = selectedVersion?.metadata as AudioVideoMetadataDto;
      const sourceFrameRate = metadata.info?.avInfo?.video?.frameRate ?? 30;
      const proxyFrameRate = Math.min(sourceFrameRate, 30);
      const frameRate = isSourceSelected ? sourceFrameRate : proxyFrameRate;
      const timeCode = comment.timeCode as any;
      if (!timeCode) return '';
      if (timeFormat === 'frames') {
        return Math.floor(timeCode[pos] * frameRate);
      }

      return formatDuration(timeCode[pos]);
    },
    [comment, isSourceSelected, selectedVersion?.metadata, timeFormat]
  );
  return (
    <div
      className="media_viewer_comment_item"
      onClick={() => {
        if (comment.id === selectedCommentId) return;
        setEditCommentId('');
        setReplyToCommentId(null);
        dispatch(selectComment(comment.id));
        if (comment.documentPage)
          dispatch(setActivePageNumber(comment.documentPage));
        dispatch(setCanvasMode('view'));
      }}
    >
      <div className="media_viewer_comment_item-top">
        <Row wrap={false} gutter={12} align="middle">
          <Col>
            <div
              className="media_viewer_comment_item-avatar-container"
              style={{
                borderColor: hashedColor(
                  comment?.user?.id || '',
                  'mediaViewerComment'
                )
              }}
            >
              <UserAvatar
                className="media_viewer_comment_item-avatar"
                isActive={true}
                size={28}
                src={comment?.user?.picture.url || ''}
                userEmail={comment?.user?.email || ''}
                userName={
                  comment.user
                    ? `${comment?.user?.firstName} ${comment?.user?.lastName}`
                    : 'Deleted User'
                }
              />
            </div>
          </Col>
          <Col>
            <Row gutter={8} align="middle">
              <Col flex="auto" className="media_viewer_comment_item-name">
                <Tooltip
                  placement="top"
                  title={
                    <>
                      {!!comment.user && (
                        <>
                          {comment.user.firstName} {comment.user.lastName}
                        </>
                      )}
                      {!comment.user && 'Deleted User'}
                    </>
                  }
                  overlayClassName="media_viewer_tooltip"
                >
                  <>
                    {!!comment.user && (
                      <>
                        {comment.user.firstName} {comment.user.lastName}
                      </>
                    )}
                    {!comment.user && 'Deleted User'}
                  </>
                </Tooltip>
              </Col>
              {selectedVersion?.permissions.manageApprovalWorkflow &&
                comment.priority && (
                  <Col>
                    <Tooltip
                      placement="top"
                      title="High priority"
                      overlayClassName="media_viewer_tooltip"
                    >
                      <HighPrioritySvg style={{ display: 'block' }} />
                    </Tooltip>
                  </Col>
                )}
              {comment.isExternal && (
                <Col>
                  <div className="media_viewer_comment_item-label">
                    External
                  </div>
                </Col>
              )}
              <Tooltip
                placement="top"
                overlayClassName="media_viewer_tooltip"
                title={
                  <>{commentLongTime(comment.updatedAt || comment.createdAt)}</>
                }
              >
                <Col className="media_viewer_comment_item-date">
                  {commentShortTime(comment.updatedAt || comment.createdAt)}
                </Col>
              </Tooltip>
              <Col className="media_viewer_comment_item-date" />
            </Row>
          </Col>
          <Col flex="auto" style={{ textAlign: 'right' }}>
            <Dropdown
              overlay={
                <Menu
                  selectable={false}
                  items={_menuItems}
                  onClick={({ key, domEvent }: any) => {
                    domEvent.stopPropagation();
                    handlerAction(key);
                  }}
                />
              }
              prefixCls="media_viewer_menu"
              overlayClassName="media_viewer_comment_item-overlay media_viewer_mobile-overlay"
              placement="bottomRight"
              trigger={['click']}
              visible={showMoreActions}
              onVisibleChange={(v) => setShowMoreActions(v)}
            >
              <ActionSvg
                onClick={(e: any) => e.stopPropagation()}
                className="media_viewer_comment_item-action"
              />
            </Dropdown>
          </Col>
        </Row>
      </div>
      <div className="media_viewer_comment_item-message">
        {(comment.timeCode || pageNumber) && (
          <div className="media_viewer_comment_item-time">
            {comment.timeCode && (
              <>
                {renderCommentTime('fromSeconds')}
                {comment.timeCode.toSeconds && (
                  <> - {renderCommentTime('toSeconds')}</>
                )}
              </>
            )}
            {pageNumber && <>Page {pageNumber}</>}
          </div>
        )}

        <div
          className="media_viewer_comment_item-message-text"
          dangerouslySetInnerHTML={{ __html: prepareHtml() }}
        />
      </div>
    </div>
  );
}

export default CommentItem;
